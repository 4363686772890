// Edit User Profile Template

//
// User details card
//

.edit-user-details {
  .card-header {
    overflow: hidden;
  }

  .card-body {
    z-index: 1;
  }

  // Profile card background image
  &__bg {
    width: 100%;
    position: relative;
    max-height: $eu-bg-image-max-height;

    img {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $eu-bg-image-overlay-bg-color;
      will-change: $eu-bg-image-overlay-will-change;
      transition: $eu-bg-image-overlay-transition;
    }

    .edit-user-details__change-background {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      opacity: 1;
      transform: $eu-bg-change-image-transform;
      background-color: $eu-bg-change-image-bg-color;
      border: $eu-bg-change-image-border;
      padding: $eu-bg-change-image-padding;
      color: $eu-bg-change-image-color;
      border-radius: $eu-bg-change-image-border-radius;
      box-shadow: $eu-bg-change-image-border-radius;
      font-size: $eu-bg-change-image-font-size;
      will-change: $eu-bg-change-image-will-change;
      transition: $eu-bg-change-image-transition;

      &:hover {
        cursor: pointer;
        transform: $eu-bg-change-image-hover-transform;
      }

      i {
        font-size: $eu-bg-change-image-icon-font-size;
        top: $eu-bg-change-image-icon-top;
      }
    }
  }

  // User avatar
  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    max-width: $eu-avatar-max-width;
    box-shadow: $eu-avatar-box-shadow;

    img {
      width: 100%;
    }

    &__change {
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      border-radius: 50%;
      font-size: $eu-avatar-change-font-size;
      background: $eu-avatar-change-background;
      transition: $eu-avatar-change-transition;

      i {
        color: $eu-avatar-change-icon-color;
        line-height: $eu-avatar-change-icon-line-height;
      }
    }

    &:hover {
      .edit-user-details__avatar__change {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
