// Borders adjustments

// Border radius
.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

// New colors variations
@each $color, $value in $new-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Accent color border variation
.border-accent {
  border-color: $accent-color !important;
}
